import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts/Layout";
import { WorkCategories, Banner } from "../components/elements";

const IndexPage = ({ data }) => {
  const node = data.allWpPage.edges[0].node;
  return (
    <Layout
      image={
        node.featuredImage.node.localFile.childImageSharp.gatsbyImageData.images
          .fallback.src
      }
      title={node.pageFields.seo_title}
      description={node.pageFields.seo_description}
      renderBanner={() => (
        <Banner title={node.title}>
          <div
            dangerouslySetInnerHTML={{
              __html: node.content,
            }}
          ></div>
        </Banner>
      )}
    >
      <WorkCategories categories={data.allWpWorkCategory.edges} />
    </Layout>
  );
};
export default IndexPage;

export const query = graphql`
  query IndexQuery {
    allWpPage(filter: { uri: { eq: "/" } }) {
      edges {
        node {
          id
          title
          uri
          content
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
          pageFields {
            seoTitle
            seoDescription
          }
        }
      }
    }
    allWpWorkCategory(
      sort: { fields: workCategoryFields___order, order: ASC }
    ) {
      edges {
        node {
          id
          title
          content
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400)
                }
              }
            }
          }
          workCategoryFields {
            page
          }
        }
      }
    }
  }
`;
